import { Popover } from "@headlessui/react";
import logo from "../assets/lift.svg";

const Header = () => {
  return (
    <header>
      <Popover className="relative bg-gray-50">
        <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
          <div className="flex flex-col sm:flex-row justify-start items-center lg:w-0 lg:flex-1">
            <a href="/">
              <span className="sr-only">Centro Tecnico Ascensori</span>
              <img className="h-8 w-auto sm:h-10" src={logo} alt="" />
            </a>
            <span className={"text-sm sm:text-lg sm:font-medium"}>
              Centro Tecnico Ascensori
            </span>
          </div>
        </div>
      </Popover>
    </header>
  );
};

export default Header;
