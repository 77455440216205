const Hero = () => {
  return (
    <div className="relative">
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
          <div className="absolute inset-0">
            <div className="absolute inset-0 bg-gradient-to-r from-blue-800 to-sky-700 mix-blend-multiply" />
          </div>
          <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
            <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
              <span className="block text-sky-200">
                Centro Tecnico Ascensori
              </span>
            </h1>
            <p className="mt-6 max-w-lg mx-auto text-center text-xl text-sky-200 sm:max-w-3xl">
              Servizio tecnico svizzero per ogni tipo di sistemi di elevazione e
              impianti automatici. Dal montaggio alla riparazione.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
