import {
  LocationMarkerIcon,
  MailIcon,
  PhoneIcon,
} from "@heroicons/react/solid";

const CallToAction = () => {
  return (
    <div className="bg-white">
      <div className="max-w-4xl mx-auto py-16 px-4 sm:px-6 lg:max-w-7xl lg:px-8 lg:flex lg:items-center lg:justify-between">
        <h2
          className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl"
          id={"contattaci"}
        >
          <span className="block bg-gradient-to-r from-blue-600 to-sky-600 bg-clip-text text-transparent">
            Contattaci
          </span>
        </h2>
        <div className="mt-9">
          <div className="flex">
            <div className="flex-shrink-0">
              <MailIcon className="h-6 w-6 text-blue-500" aria-hidden="true" />
            </div>
            <div className="ml-3 text-base text-gray-500">
              <a
                href={"mailto:info@centrotecnico.ch"}
                className={"font-medium cursor-pointer hover:text-blue-700"}
              >
                info@centrotecnico.ch
              </a>
            </div>
          </div>
          <div className="mt-6 flex">
            <div className="flex-shrink-0">
              <PhoneIcon className="h-6 w-6 text-blue-500" aria-hidden="true" />
            </div>
            <div className="ml-3 text-base text-gray-500">
              <a
                href={"tel:+41(0)916302981"}
                className={"font-medium cursor-pointer hover:text-blue-700"}
              >
                +41 (0)91 6302981
              </a>
            </div>
          </div>
          <div className="mt-6 flex">
            <div className="flex-shrink-0">
              <LocationMarkerIcon
                className="h-6 w-6 text-blue-500"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3 text-base text-gray-500">
              <a
                className={"cursor-pointer hover:text-blue-700 font-medium"}
                href={"https://goo.gl/maps/NCGc9QdWBfweUW6v9"}
              >
                <span
                  className={"w-full flex gap-2 justify-between items-center"}
                >
                  Via Giuseppe Motta 8
                </span>
                <span
                  className={"w-full flex gap-2 justify-between items-center"}
                >
                  Ch-6828
                </span>
                <span
                  className={"w-full flex gap-2 justify-between items-center"}
                >
                  Balerna Svizzera
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallToAction;
